import firebase from 'firebase/app'

export const API_KEY = "AIzaSyAZrYrIN4oJQ1QNMU42gnO8ahrokG3zUm8"

firebase.initializeApp({
  apiKey: API_KEY,
  authDomain: "taplanner.firebaseapp.com",
  databaseURL: "https://taplanner.firebaseio.com",
  projectId: "taplanner",
  storageBucket: "taplanner.appspot.com",
  messagingSenderId: "232527274272"
})

const firestorePromise = import('firebase/firestore').then(() => {
  firebase.firestore().enablePersistence()
    .catch(err => alert(err.message))
})

export async function firestore() {
  await firestorePromise
  return firebase.firestore()
}

const authPromise = import('firebase/auth')

export async function auth() {
  await authPromise
  return firebase.auth()
}

export function geopoint(lat, long) {
  // TODO: does firestore need to be imported? makes it awkward needing to wrap in a promise...
  return new firebase.firestore.GeoPoint(parseFloat(lat), parseFloat(long))
}