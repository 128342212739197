import firebase from 'firebase/app'
import { firestore } from './index'
import requestFactory from '../stores/requestFactory';
import { get, writable } from 'svelte/store';

export const request = requestFactory()

const notes_ = writable([])

function getDocRef(db, tripId, restaurantId) {
  return db.collection('trips').doc(tripId).collection('notes').doc(restaurantId)
}

function _getLocal(tripId, restaurantId) {
  const curNotes = get(notes_)
  const filtered = curNotes.filter(n => n.tripId === tripId && n.restaurantId === restaurantId)
  if (filtered.length > 0) {
    return filtered[0]
  }
  return null
}

export default {
  subscribe: notes_.subscribe,
  get(tripId, restaurantId) {
    // TODO: refactor this code out, appears 3 times
    console.log(restaurantId, tripId)
    if (!tripId || !restaurantId) return Promise.resolve(null)
    const local = _getLocal(tripId, restaurantId)
    if (local != null) {
      return Promise.resolve(local)
    }
    console.log('fetching note', tripId, restaurantId)
    return request.perform(
      (async function() {
        const db = await firestore()
        const docRef = await getDocRef(db, tripId, restaurantId).get()
        if (!docRef.exists) return null
        const cur = {
          tripId,
          restaurantId,
          ...docRef.data()
        }
        notes_.update(ns => ns.concat([cur]))
        return cur
      })()
    ).then(req => req.result)
  },
  addNote(tripId, restaurantId, note) {
    return request.perform(
      (async function() {
        const db = await firestore()
        await getDocRef(db, tripId, restaurantId).set({
          notes: firebase.firestore.FieldValue.arrayUnion(note),
        }, {merge: true})

        notes_.update(ns => {
          const index = ns.findIndex(n => n.tripId === tripId && n.restaurantId === restaurantId)
          if (index >= 0) {
            ns[index].notes.push(note)
          }
          return ns
        })
      })()
    )
  },
  markVisited(tripId, restaurantId, visited) {
    return request.perform(
      (async function() {
        const db = await firestore()
        const docRef = db.collection('trips').doc(tripId).collection('notes').doc(restaurantId)
        await docRef.update({visited})
      })()
    )
  },
  clear() {
    notes_.set([])
  },
}