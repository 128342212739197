<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  
  export let value
</script>

<div class="container">
  <input type="text" bind:value={value} placeholder="TripAdvisor URL">
  <button class="icon" on:click={() => dispatch('delete')}>❌</button>
</div>

<style>
  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 0.25rem;
    margin: 7px 0 7px 0;
  }
  
  input {
    flex: 1;
    line-height: 1.5rem;
  }
</style>