<script>
  export let overallRating
  export let reviewCount
  export let localRanking
  export let ratingQuestions

  function renderStars(rating) {
    return ('★'.repeat(Math.floor(rating))) + (rating > Math.floor(rating) ? '½' : '')
  }

  $: overallStars = renderStars(overallRating)
  $: ratingQuestionsArr = Object.entries(ratingQuestions).map(([q, score]) => ({
    question: q,
    stars: renderStars(score / 10.0),
  }))
</script>

<div class="overview">
  <span class="stars">{overallStars}</span>
  <span class="reviews">({reviewCount} review{reviewCount === 1 ? '' : 's'})</span>
  <br>
  <span class="ranking">#{localRanking.rank} of {localRanking.totalCount} in {localRanking.geo}</span>
</div>
<div class="subratings">
  {#each ratingQuestionsArr as {question, stars}}
    <div>
      <span class="question">{question}:</span>
      <span class="stars small">{stars}</span>
    </div>
  {/each}
</div>

<style>
  .stars {
    position: relative;
    display: inline-block;
    font-size: 26px;
    color: rgb(255, 174, 0);
  }

  .stars.small {
    font-size: inherit;
  }

  .reviews, .ranking {
    vertical-align: top;
    line-height: 30px;
  }
</style>