<script>
  import Spinner from '../Spinner.svelte'
  import Error from '../Error.svelte'
  import notesService, { request } from '../../firebase/notes'
  import { readable } from 'svelte/store'

  export let tripId
  export let restaurantId

  let newNote = ''

  let notes = readable(null, function start(set) {
    notesService.get(tripId, restaurantId).then(res => set(res))
    // Listen to updates and re-set the value
    // TODO: this is so hacky, and defeats the purpose of stores
    const unsub = notesService.subscribe(() => notesService.get(tripId, restaurantId).then(res => set(res)))
    return function stop() {
      unsub()
    }
  })

  function addNote() {
    if (!newNote) return
    notesService.addNote(tripId, restaurantId, newNote)
    newNote = ''
  }
</script>

<h3>Notes</h3>

{#if $request.loading}
  <Spinner />
{:else if $request.error}
  <Error error={$request.error}/>
{:else}
  {#if $notes}
    <ul>
      {#each $notes.notes as note}
        <li>{note}</li>
      {/each}
    </ul>
  {:else}
    <div>No notes yet</div>
  {/if}
  <form on:submit|preventDefault={addNote} autocomplete="off">
    <input name="new-note" type="text" bind:value={newNote} placeholder="Add a note">
    <button type="submit" class="blue" disabled={!newNote}>Add</button>
  </form>
{/if}

<style>
  form {
    display: flex;
    margin-top: 10px;
  }

  ul {
    width: 80%;
    margin: 0 auto;
    text-align: left;
  }
</style>