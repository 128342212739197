import { readable, get } from 'svelte/store'

const getHash = () => window.location.hash.replace(/#\/?|\/$/g, '').split('/')

export default readable(getHash(), set => {
  window.onhashchange = () => {
    set(getHash())
  }
})

export const curHash = () => get(hash)