<script>
  export let reviewTags
  export let features
  export let price
  export let priceLevel
  export let cuisines
  export let tripAdvisorUrl
  export let website
  export let phone

  $: priceStr = price || priceLevel
</script>

{#if priceStr}
  <div>
    <span class="header">Price</span>
    <span>{priceStr}</span>
  </div>
{/if}

{#if cuisines}
  <div>
    <span class="header block">Cuisines</span>
    {#each cuisines as tag}
      <span class="tag">{tag}</span>
    {/each}
  </div>
{/if}

{#if reviewTags}
  <div>
    <span class="header block">Review Tags</span>
    {#each reviewTags as tag}
      <span class="tag">{tag}</span>
    {/each}
  </div>
{/if}

{#if features}
  <div>
    <span class="header block">Features</span>
    {#each features as tag}
      <span class="tag">{tag}</span>
    {/each}
  </div>
{/if}


<div class="urls">
  <a href={tripAdvisorUrl}>View on TripAdvisor</a>
  {#if website}
    <a href={website}>Restaurant Website</a>
  {/if}
</div>

<style>
  div {
    margin-bottom: 5px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  .header {
    font-weight: bold;
  }

  .tag {
    display: inline-block;
    padding: 5px;
    margin: 3px;
    border-radius: .25rem;
    background-color: #777;
    color: #eee;
  }

  a {
    display: block;
    margin-bottom: 10px;
  }
</style>