<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let id
  export let name
  export let deleted = false
</script>

<div class="container">
  <div class="strike" class:on={deleted}>{name}</div>
  <a class="icon" on:click={() => dispatch('toggleDelete', id)} href="javascript: void 0">
    {deleted ? '⟲' : '❌'}
  </a>
</div>

<style>
  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 0.25rem;
    text-align: center;
    margin: 7px 0 7px 0;
  }

  .strike {
    position: relative;
    transition: color 300ms;
    line-height: 30px;
  }

  .strike:after {
    content: '';
    width: 0;
    height: 2px;
    display: block;
    background: black;
    transition: 300ms;
    position: absolute;
    top: 50%;
    left: 0;
  }

  .strike.on {
    color: #999;
  }

  .strike.on:after {
    width:100%;
    background-color: #555;
  }
</style>