<script>
  import hash from '../stores/hash'
  import trips, { request as tripRequest } from '../firebase/trips'
  import restaurantService, { request as restaurantRequest } from '../firebase/restaurants'
  import RestaurantListItem from '../components/ModifyTrip/RestaurantListItem.svelte'
  import RestaurantInputField from '../components/ModifyTrip/RestaurantInputField.svelte'
  import Error from '../components/Error.svelte'
  import Spinner from '../components/Spinner.svelte'
  import { derived } from 'svelte/store'

  let request = derived([tripRequest, restaurantRequest], ([$tripRequest, $restaurantRequest]) => ({
    loading: $tripRequest.loading || $restaurantRequest.loading,
    error: $tripRequest.error || $restaurantRequest.error,
  }))
  // TODO: refactor duplicated code
  let tripId = derived(hash, ($hash) => $hash[1])
  let trip = derived([tripId, trips], ([$id, _], set) => trips.get($id).then((res) => set(res)))
  let title = derived(trip, ($trip) => {
    if (!$trip || !$trip.name) return 'My Trip'
    return `My Trip to ${$trip.name}`
  })

  let restaurants = derived(trip, ($trip) => {
    if ($trip && $trip.restaurants) {
      return $trip.restaurants
    }
    return []
  })

  let urls = []
  let toRemove = []

  function toggle(restaurant) {
    const removed = toRemove.filter(j => j.id !== restaurant.id)
    if (removed.length === toRemove.length) {
      return [...toRemove, restaurant]
    } else {
      return removed
    }
  }

  $: willRemove = (restaurant) => !!toRemove.filter(r => r.id === restaurant.id).length
  $: anyChanges = !!toRemove.length || !!urls.filter(url => !!url).length

  function remove(i) {
    urls.splice(i, 1)
    return urls
  }

  function update(tripId) {
    restaurantService.update(tripId, urls, toRemove)
    urls = []
    toRemove = []
  }
</script>

<svelte:head>
  <title>{$title}</title>
</svelte:head>

<a class="back" href="#/trips/{$tripId}">◀</a>

{#if $request.loading}
  <Spinner/>
{:else if $request.error}
  <Error error={$request.error}/>
{:else}
  <h1>Modify Restaurants</h1>
  {#each $restaurants as restaurant}
    <RestaurantListItem
      {...restaurant}
      deleted={willRemove(restaurant)}
      on:toggleDelete={() => toRemove = toggle(restaurant)} />
  {/each}
  {#each urls as url, i}
    <RestaurantInputField bind:value={urls[i]} on:delete={() => urls = remove(i)} />
  {/each}
  <a class="nolink add-restaurant" href="javascript: void 0" on:click={() => urls = [...urls, '']}>
    <h3>Add a restaurant</h3>
    <span class="icon add">➕</span>
  </a>
  <button class="blue" on:click={() => update($tripId)} disabled={!anyChanges}>Update</button>
{/if}

<style>
  .add {
    color: transparent;
    text-shadow: 0 0 0 green;
  }

  .add-restaurant {
    margin-bottom: 10px;
    line-height: 30px;
  }

  .add-restaurant h3 {
    display: inline;
  }

  button {
    display: block;
    margin: 10px auto;
  }
</style>