<script>
  import TripListItem from '../components/Trips/TripListItem.svelte'
  import Error from '../components/Error.svelte'
  import Spinner from '../components/Spinner.svelte'
  import auth from '../firebase/auth'
  import trips, { request } from '../firebase/trips'
  import { writable } from 'svelte/store'

  trips.list()

  let name = ''

  function create() {
    if (!name || request.isLoading()) {
      return
    }
    trips.create(name)
      .then(() => name = '')
  }

  function logout() {
    auth.logout()
  }

  function fetchAll() {
    trips.fetchAll()
      .then(() => alert('done fetching'))
  }
</script>

<svelte:head>
  <title>My Trips</title>
</svelte:head>

<div class="container">
  <h1>My Trips</h1>
  <hr>

  {#if $request.loading}
    <Spinner />
  {:else if $request.error}
    <Error error={$request.error}/>
  {:else}
    {#if $trips && $trips.length}
      {#each $trips as trip}
        <TripListItem {...trip} />
      {/each}
    {:else}
      <p>You have no trips</p>
    {/if}
    <h4>Add a new trip</h4>
    <form on:submit|preventDefault={create}>
      <div class="input-group">
        <input type="text" name="destination" placeholder="Destination" bind:value={name}>
        <button type="submit" class="blue" disabled={$request.loading}>Add</button>
      </div>
    </form>
  {/if}

  <a href="javascript: void 0" on:click={logout}>Logout</a>

  <a href="javascript: void 0" on:click={fetchAll}>Fetch all</a>
</div>

<style>
  .container {
    text-align: center;
  }

  .input-group {
    display: flex;
    justify-content: center;
  }

  input {
    flex: 1;
  }
  
  h4 {
    margin-top: 15px;
  }
</style>