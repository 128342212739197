import { readable } from 'svelte/store'

// https://stackoverflow.com/a/36146278/9994448
function getTimezoneOffset(d, tz) {
  var a = d.toLocaleString("ja", {timeZone: tz}).split(/[\/\s:-]/)
  a[1]--
  var t1 = Date.UTC.apply(null, a)
  var t2 = new Date(d).setMilliseconds(0)
  return (t2 - t1) / 60 / 1000
}

export default function dateFactory(timezone) {
  const localOffset = new Date().getTimezoneOffset()
  const remoteOffset = timezone ? getTimezoneOffset(new Date(), timezone) : localOffset
  const getDate = () => {
    const date = new Date()
    date.setMinutes(date.getMinutes() + localOffset - remoteOffset)
    return date
  }
  const store = readable(getDate(), function start(set) {
    const interval = setInterval(() => set(getDate()), 1000)
    return function stop() {
      clearInterval(interval)
    }
  })
  return store
}