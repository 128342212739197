import { writable, get } from "svelte/store";

export default function requestFactory() {
  const cleanRequest = {
    result: null,
    loading: false,
    error: null,
  }
  const base = writable(cleanRequest)
  const {subscribe, set: setBase} = base
  const set = v => {
    setBase(v)
    return v
  }
  return {
    subscribe,
    perform(promise) {
      set({
        result: null,
        loading: true,
        error: null,
      })
      return promise
        .then(res => set({
          result: res,
          loading: false,
          error: null,
        }))
        .catch(err => {
          console.error(err)
          return set({
            result: null,
            loading: false,
            error: err,
          })
        })
    },
    reset() {
      set(cleanRequest)
    },
    isLoading() {
      return get(base).loading
    }
  }
}