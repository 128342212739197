import { firestore } from './index'
import requestFactory from '../stores/requestFactory';
import { get, writable } from 'svelte/store';
import { uid } from './auth'
import restaurants from './restaurants'
import notes from './notes'

export const request = requestFactory()

let hasListed = false
const trips = writable([])

function _get(id) {
  if (!id) return Promise.resolve(null)
  const curTrips = get(trips)
  const filtered = curTrips.filter(t => t.id === id)
  if (filtered.length > 0) {
    return Promise.resolve(filtered[0])
  }
  console.log('fetching', id)
  return request.perform(
    (async function() {
      const db = await firestore()
      const docRef = await db
        .collection('trips')
        .doc(id)
        .get()
      if (!docRef.exists) return null
      const trip = {
        id: docRef.id,
        ...docRef.data(),
      }
      trips.update(ts => ts.concat([trip]))
      return trip
    })()
  ).then(req => req.result)
}

export async function _updateRestaurants(id, additions, removals) {
  const self = await _get(id)
  if (!self.restaurants || !self.restaurants.length) self.restaurants = []
  const removalIds = removals.map(r => r.id)
  self.restaurants = self.restaurants.concat(additions).filter(r => !removalIds.includes(r.id))
  trips.update($trips => {
    const i = $trips.indexOf(self.id)
    $trips[i] = self
    return $trips
  })
}

export default {
  subscribe: trips.subscribe,
  list() {
    if (hasListed) return Promise.resolve()
    console.log('listing...')
    return request.perform(
      firestore()
        .then(
          db => db
            .collection('trips')
            .where('uid', '==', uid())
            .orderBy('timestamp')
            .get()
        )
        .then(r => r.docs.map(docRef => ({
          id: docRef.id,
          ...docRef.data()
        })))
        .then(t => {
          trips.set(t)
          hasListed = true
        })
    )
  },
  create(name) {
    const doc = {
      uid: uid(),
      name,
      timestamp: new Date(),
    }
    return request.perform(
      firestore()
        .then(
          db => db
            .collection('trips')
            .add(doc)
        )
        .then(
          docRef => trips.update(t => t.concat([{
            id: docRef.id,
            ...doc
          }]))
        )
    )
  },
  get(id) {
    return _get(id)
  },
  async fetchAll() {
    // presumes list() has finished...
    const $trips = get(trips)
    await Promise.all(
      $trips.map(async t => {
        if (t.restaurants && t.restaurants.length) {
          await Promise.all(
            t.restaurants.map(
              r => Promise.all([
                restaurants.get(r.id),
                notes.get(t.id, r.id),
              ])
            )
          )
        }
      })
    )
  },
  clear() {
    trips.set([])
    hasListed = false
  },
}