export function getRouteParams(hash, path) {
  if (hash.length !== path.length) return null;

  const params = {}
  for (let i = 0; i < hash.length; i++) {
    if (isRouteParam(path[i])) {
      params[path[i].param] = hash[i]
    } else {
      if (hash[i] !== path[i]) return null;
    }
  }
  return params;
}

function isRouteParam(v) {
  return v && typeof v === 'object' && v.param
}

export class Settable {
  constructor(defaultValue) {
    this.value = defaultValue
  }

  get() {
    return this.value
  }

  set(value) {
    this.value = value
  }
}

export function userLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => resolve({lat: pos.coords.latitude, lng: pos.coords.longitude}),
        () => reject(`Error: Geolocation service failed`),
      )
    } else {
      reject(`Error: Your browser doesn\'t support geolocation`)
    }
  })
}

export function userMarker(map, position) {
  return new google.maps.Marker({
    clickable: false,
    icon: new google.maps.MarkerImage('//maps.gstatic.com/mapfiles/mobile/mobileimgs2.png',
                                      new google.maps.Size(22,22),
                                      new google.maps.Point(0,18),
                                      new google.maps.Point(11,11)),
    shadow: null,
    zIndex: 999,
    map,
    position,
  });
}