<script>
  export let error

  $: message = error.message || 'An error occurred'
</script>

<div>
  {message}
</div>

<style>
  div {
    color: rgb(175, 0, 0);
    margin-bottom: 10px;
  }
</style>