import { API_KEY, auth as getAuth } from './index'
import { get, writable } from 'svelte/store'
import requestFactory from '../stores/requestFactory';

import notes from './notes'
import trips from './trips'
import restaurants from './restaurants';

const authState = writable(null)

const firebaseAuthPromise = getAuth().then(auth => {
  auth.onAuthStateChanged(user => {
    updateAuthStore(user, true)
  })
  return auth
})

export const request = requestFactory()

export default {
  subscribe: authState.subscribe,
  login(email, password) {
    return request.perform(
      firebaseAuthPromise.then(
        firebaseAuth => firebaseAuth.signInWithEmailAndPassword(email, password)
      )
    )
  },
  register(email, password) {
    return request.perform(
      firebaseAuthPromise.then(
        firebaseAuth => firebaseAuth.createUserWithEmailAndPassword(email, password)
      )
    )
  },
  logout() {
    return request.perform(
      firebaseAuthPromise
        .then(firebaseAuth => firebaseAuth.signOut())
        .then(() => {
          notes.clear()
          trips.clear()
          restaurants.clear()
        })
    )
  }
}

export const uid = () => (get(authState) || {}).uid

function updateAuthStore(user, verified) {
  if (user) {
    authState.set({
      uid: user.uid,
      email: user.email,
      verified,
    })
  } else {
    authState.set(null)
  }
}

export function precheckAuth() {
  console.log('prechecking auth')
  if (!window.indexedDB) return Promise.resolve(null)
  console.log('no problem with indexedDB')
  // https://github.com/firebase/firebase-js-sdk/blob/6b53e0058483c9002d2fe56119f86fc9fb96b56c/packages/auth/src/storage/indexeddb.js#L191
  const dbName = 'firebaseLocalStorageDb'
  // https://github.com/firebase/firebase-js-sdk/blob/6b53e0058483c9002d2fe56119f86fc9fb96b56c/packages/auth/src/storage/indexeddb.js#L219
  const dbVersion = 1
  // https://github.com/firebase/firebase-js-sdk/blob/6b53e0058483c9002d2fe56119f86fc9fb96b56c/packages/auth/src/storage/indexeddb.js#L198
  const objectStoreName = 'firebaseLocalStorage'

  return new Promise(resolve => {
    const succeed = user => {
      console.log('succeeded')
      updateAuthStore(user, false)
      resolve(null)
    }
    const fail = (err) => {
      console.log('failed', err)
      updateAuthStore(null, true)
      resolve(null)
    }
    let request
    try {
      request = window.indexedDB.open(dbName, dbVersion)
    } catch(err) {
      fail(err)
      return
    }
    request.onsuccess = ev => {
      const db = ev.target.result
      try {
        const request = db.transaction([objectStoreName], "readonly")
                          .objectStore(objectStoreName)
                          .get(`firebase:authUser:${API_KEY}:[DEFAULT]`)
        request.onsuccess = e => {
          const res = e.target.result
          if (res) {
            succeed(res.value)
          } else {
            fail(e)
          }
        }
        request.onerror = fail
      } catch (err) {
        fail(err)
      }
    }
    request.onerror = fail
  })
}