<script>
  import { onMount, tick } from 'svelte'
  import hash from '../stores/hash'
  import trips from '../firebase/trips'
  import restaurants, { request } from '../firebase/restaurants'
  import Address from '../components/Restaurant/Address.svelte'
  import Rating from '../components/Restaurant/Rating.svelte'
  import Hours from '../components/Restaurant/Hours.svelte'
  import Details from '../components/Restaurant/Details.svelte'
  import Notes from '../components/Restaurant/Notes.svelte'
  import Error from '../components/Error.svelte'
  import Spinner from '../components/Spinner.svelte'
  import { derived, get } from 'svelte/store'
  import { userLocation, userMarker } from '../util'

  let tripId = derived(hash, ($hash) => $hash[1])
  let restaurantId = derived(hash, ($hash) => $hash[3])
  let restaurant = derived(restaurantId, ($id, set) => restaurants.get($id).then(res => set(res)))

  let title = derived(restaurant, $restaurant => {
    if (!$restaurant || !$restaurant.name) return 'Restaurant'
    return $restaurant.name
  })

  var map;

  onMount(() => {
    const userLocationPromise = new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          pos => resolve(new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude)),
          () => reject(`Error: Geolocation service failed`),
        )
      } else {
        reject(`Error: Your browser doesn\'t support geolocation`)
      }
    })
    // Subscribe to both so that we aren't loading
    return restaurant.subscribe(async ($restaurant) => {
      if ($restaurant) {
        const coords = new google.maps.LatLng($restaurant.coords.latitude, $restaurant.coords.longitude)
        const userLocationPromise = userLocation()
        await tick()
        map = new google.maps.Map(document.getElementById('map'), {
          disableDefaultUI: true,
          gestureHandling: 'greedy',
          center: coords,
          zoom: 15,
        })

        const marker = new google.maps.Marker({
            position: coords,
            map,
            title: $restaurant.name,
          })
        
        // TODO: remove duplication
        try {
          const position = await userLocationPromise
          userMarker(map, position)
        } catch(err) {
          console.error('failed to get position', err)
        }
      }
    })
  })
  
</script>

<svelte:head>
  <title>{$title}</title>
</svelte:head>

<a class="back" href="#/trips/{$tripId}">◀</a>

{#if $request.loading}
  <Spinner/>
{:else if $request.error}
  <Error error={$request.error}/>
{:else}
  <h1>{$title}</h1>
  {#if $restaurant}
    <div id="map"></div>
    <Address addressString={$restaurant.addressString} />
    <Rating {...$restaurant.ratingInfo} />
    <Hours {...$restaurant} />
    <Details {...$restaurant} />
    <Notes tripId={$tripId} restaurantId={$restaurantId} />
  {/if}
{/if}

<style>
  #map {
    width: 100%;
    max-width: 768px;
    height: 300px;
    margin: 0 auto 15px;
  }
</style>