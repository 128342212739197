<script>
  import { createEventDispatcher } from 'svelte';
  import Address from '../Restaurant/Address.svelte'

  const dispatch = createEventDispatcher();
  export let tripId
  export let id
  export let name
  export let address
  export let price
</script>

<div class="container" >
  <a class="nolink" href="#/trips/{tripId}/restaurants/{id}">
    <h3>{name}</h3>
  </a>
  <div>{price}</div>
  <Address addressString={address} />
</div>

<style>
  .container {
    justify-content: center;
    width: 100%;
    border-radius: 0.25rem;
    text-align: center;
    padding: 7px;
  }

  .container:hover {
    background-color: #DDD;
  }
</style>