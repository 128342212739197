<script>
  import Login from './views/Login.svelte'
  import Trips from './views/Trips.svelte'
  import Trip from './views/Trip.svelte'
  import ModifyTrip from './views/ModifyTrip.svelte'
  import Restaurant from './views/Restaurant.svelte'
  import hash from './stores/hash'
  import auth, { precheckAuth } from './firebase/auth'
  import { onMount, onDestroy } from 'svelte'
  import { derived, writable } from 'svelte/store'
  import { getRouteParams, Settable } from './util'


  const donePrecheck = writable(false)

  const precheckPromise = precheckAuth()
    .then(() => {
      console.log('done precheck')
      donePrecheck.set(true)
    })
    .catch(() => {
      console.log('caught precheck?')
      donePrecheck.set(true)
    })

  const routes = [
    {
      path: ['login'],
      component: Login,
      requiresAuth: false,
    },
    {
      path: ['trips'],
      component: Trips,
      requiresAuth: true,
    },
    {
      path: ['trips', {param: 'tripId'}],
      component: Trip,
      requiresAuth: true,
    },
    {
      path: ['trips', {param: 'tripId'}, 'modify'],
      component: ModifyTrip,
      requiresAuth: true,
    },
    {
      path: ['trips', {param: 'tripId'}, 'restaurants', {param: 'restaurantId'}],
      component: Restaurant,
      requiresAuth: true,
    },
  ]

  const params = new Settable({})

  let currentRoute = derived([hash, auth, donePrecheck], function getRoute([$hash, $auth, $donePrecheck]) {
    console.log(+new Date(), $hash, $auth, $donePrecheck)
    if (!$donePrecheck) return
    for (const route of routes) {
      const routeParams = getRouteParams($hash, route.path)
      const matches = !!routeParams
      if (matches) {
        if (!$auth && route.requiresAuth) {
          window.location.hash = '#/login'
          return routes[0] // login
        }
        if ($auth && !route.requiresAuth) {
          window.location.hash = '#/trips'
          return routes[1] // trips
        }
        params.set(routeParams)
        return route
      }
    }
    // No matches
    if ($auth) {
      history.replaceState(null, null, '#/trips');
      return routes[1]
    } else {
      history.replaceState(null, null, '#/login');
      return routes[0]
    }
  })
</script>

{#await precheckPromise}
{:then _}
  {#if $currentRoute}
    <svelte:component this={$currentRoute.component} {...params.get()} />
  {/if}
{/await}