import firebase from 'firebase/app'
import { _updateRestaurants } from './trips'
import { firestore, geopoint } from './index'
import requestFactory from '../stores/requestFactory';
import { get, writable } from 'svelte/store';

const FUNCTION_URL = 'https://us-central1-taplanner.cloudfunctions.net/fetch'

export const request = requestFactory()

const restaurants_ = writable([])

export default {
  subscribe: restaurants_.subscribe,
  get(id) {
    if (!id) return Promise.resolve(null)
    const curRestaurants = get(restaurants_)
    const filtered = curRestaurants.filter(r => r.locationId === id)
    if (filtered.length > 0) {
      return Promise.resolve(filtered[0])
    }
    console.log('fetching', id)
    return request.perform(
      (async function() {
        const db = await firestore()
        const docRef = await db
          .collection('restaurants')
          .doc(id)
          .get()
        if (!docRef.exists) return null
        const restaurant = docRef.data()
        restaurants_.update(rs => rs.concat([restaurant]))
        return restaurant
      })()
    ).then(req => req.result)
  },
  update(tripId, urls, toRemove) {
    console.log('updating...', urls, toRemove)
    return request.perform(
      (async function() {
        const db = await firestore()
        let toAdd = []
        if (urls.length) {
          const response = await fetch(FUNCTION_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(urls)
          })
          const restaurants = await response.json()
          const validRestaurants = []
          const invalidRestaurants = []
          const batch = db.batch()
          for (const restaurant of restaurants) {
            if (restaurant.error) {
              invalidRestaurants.push(restaurant)
            } else {
              // TODO: add security rules to restaurant to enforce schema
              restaurant.data.coords = geopoint(restaurant.data.latitude, restaurant.data.longitude)
              batch.set(db.collection('restaurants').doc(restaurant.id), restaurant.data)
              validRestaurants.push(restaurant.data)
            }
          }
          await batch.commit()
          restaurants_.update(r => r.concat(validRestaurants))
          toAdd = validRestaurants.map(r => ({
            id: r.locationId,
            name: r.name,
            coords: r.coords,
            address: r.addressString,
            price: r.price || r.priceLevel || '',
          }))
          if (toAdd.length) {
            await db.collection('trips').doc(tripId).update({
              restaurants: firebase.firestore.FieldValue.arrayUnion(...toAdd),
            })
          }
        }
        if (toRemove.length) {
          await db.collection('trips').doc(tripId).update({
            restaurants: firebase.firestore.FieldValue.arrayRemove(...toRemove)
          })
        }
        await _updateRestaurants(tripId, toAdd, toRemove)
        return {
          added: toAdd,
          removed: toRemove,
        }
      })()
    )
  },
  clear() {
    restaurants_.set([])
  },
}