<script>
  import Error from '../components/Error.svelte'
  import auth, { request } from '../firebase/auth'

  let email = ""
  let password = ""
</script>

<svelte:head>
  <title>Login</title>
</svelte:head>

<div class="page">
  <h1>Login</h1>

<form class="login" on:submit|preventDefault={() => auth.login(email, password)}>
  <div class="input-group">
    <label for="email">Email</label>
    <input bind:value={email} type="email" name="email" id="email" autocomplete="email">
  </div>
  <div class="input-group">
    <label for="password">Password</label>
    <input bind:value={password} type="password" name="password" id="password" autocomplete="current-password">
  </div>
  {#if $request.error}
    <Error error={$request.error}/>
  {/if}
  <div class="buttons">
    <button type="submit" class="blue" disabled={$request.loading}>
      Login
    </button>
    <button type="button" class="green" disabled={$request.loading} on:click={() => auth.register(email, password)}>
      Sign Up
    </button>
  </div>
</form>
</div>

<style>
  .page {
    text-align: center;
  }

  .input-group {
    margin-bottom: 15px;
  }

  label {
    display: block;
  }

  button:first {
    margin-right: 15px;
  }
</style>