<script>
  import dateFactory from '../../stores/dateFactory'
  import { derived } from 'svelte/store'

  export let hours
  export let displayHours

  function zeroPad(v) {
    let s = '' + v
    while (s.length < 2) s = '0' + s
    return s
  }

  function humanDuration(mins) {
    if (mins <= 1) return `1 minute` // assume mins is positive, but this is a fallback
    if (mins <= 50) return `${mins} minutes`
    if (mins <= 65) return `about an hour`
    if (mins <= 80) return `just over an hour`
    if (mins <= 100) return `an hour and a half`
    const hours = Math.round(mins / 60.0)
    if (hours <= 20) return `${hours} hours`
    const days = Math.round(mins / 60.0 / 24.0)
    if (days === 1) return `1 day`
    return `${days} days`
  }
  function formatTime(time) {
    time %= 24 * 60
    let hours = Math.floor(time / 60)
    const minutes = time % 60
    const ampm = hours >= 12 ? 'p.m.' : 'a.m.'
    if (hours === 0) hours = 12
    else if (hours > 12) hours %= 12
    return `${hours}:${zeroPad(minutes)}${ampm}`
  }

  // TODO: read the timezone from hours
  let date = dateFactory('Asia/Tokyo')
  let minutes = derived(date, $date => $date.getHours() * 60 + $date.getMinutes())
  let day = derived(date, $date => $date.getDay())

  let openStatus = derived([day, minutes], ([$day, $minutes]) => {
    const today = hours[$day]
    if (!today.ranges) return {unknown: true}
    const isOpen = (range) => $minutes >= range.openTime && $minutes <= range.closeTime
    const tomorrow = (day) => (day + 1) % 7
    const wrapsAround = (rangeToday, rangesTomorrow) => {
      if (!rangesTomorrow || !rangesTomorrow.length) return false
      const firstRangeTomorrow = rangesTomorrow[0]
      return rangeToday.closeTime === 24 * 60 - 1 && firstRangeTomorrow.openTime === 0
    }
    const open24Hours = (() => 
      hours.every(
        ({ranges}) => ranges && ranges.length === 1 && ranges[0].openTime === 0 && ranges[0].closeTime >= 24 * 60 - 1
      )
    )()
    if (open24Hours) {
      return {
        open: true,
        msg: `Open 24 hours`,
      }
    }
    for (const range of today.ranges) {
      if (isOpen(range)) {
        const closesIn = range.closeTime - $minutes
        if (closesIn < 90 && !wrapsAround(range, hours[tomorrow($day)].ranges)) {
          return {
            open: true,
            msg: `Closing in ${humanDuration(closesIn)} (${formatTime(range.closeTime)})`,
          }
        }
        return {
          open: true,
          msg: `Open until ${formatTime(range.closeTime)}`,
        }
      } else if ($minutes < range.openTime) {
        const opensIn = range.openTime - $minutes
        return {
          open: false,
          msg: `Opens in ${humanDuration(opensIn)}`,
        }
      }
    }
    // TODO: closes past midnight, closeTime > 24 * 60...have to look at previous day as well
    const daysAhead = (futureDay) => {
      if (futureDay < $day) return futureDay - $day + 7
      return futureDay - $day
    }
    let curDay = tomorrow($day)
    while (curDay !== $day) {
      if (hours[curDay].ranges.length > 0) {
        return {
          open: false,
          msg: `Opens in ${humanDuration(hours[curDay].ranges[0].openTime + daysAhead(curDay) * 24 * 60 - $minutes)}`
        }
      }
      curDay = tomorrow(curDay)
    }
    return null
  })
</script>

{#if $openStatus.unknown}
  Hours unknown
{:else}
  <div>
    {#if $openStatus.open}
      <span class="icon check">✓</span>
    {:else}
      <span class="icon x">✗</span>
    {/if}
    {$openStatus.msg}
  </div>
  <div>
    {#each displayHours as {days, times}}
      <div>
        <span class="days">{days}</span>
        {#each times as time, i}
          <span>{time}</span>
          {#if i < times.length - 1}<span class="comma">,</span>{/if}
        {/each}
      </div>
    {/each}
  </div>
{/if}

<style>
  .days {
    font-weight: bold;
  }

  .check {
    color: green;
  }

  .x {
    color: red;
  }

  .comma {
    padding-right: 3px;
  }
</style>