<script>
  import { onMount, tick } from 'svelte'
  import hash from '../stores/hash'
  import trips, { request } from '../firebase/trips'
  import RestaurantListItem from '../components/Trip/RestaurantListItem.svelte'
  import Error from '../components/Error.svelte'
  import Spinner from '../components/Spinner.svelte'
  import { derived, get } from 'svelte/store'
  import { userLocation, userMarker } from '../util'

  let tripId = derived(hash, ($hash) => $hash[1])
  let trip = derived(tripId, ($id, set) => trips.get($id).then((res) => set(res)))
  let title = derived(trip, ($trip) => {
    if (!$trip || !$trip.name) return 'My Trip'
    return `My Trip to ${$trip.name}`
  })

  let restaurants = derived(trip, ($trip) => {
    if ($trip && $trip.restaurants) {
      return $trip.restaurants
    }
    return []
  })

  var map;

  onMount(() => {
    const userLocationPromise = userLocation()
    return restaurants.subscribe(async $restaurants => {
      if ($restaurants.length) {
        await tick()
        for (const res of $restaurants) {
          res.latlng = new google.maps.LatLng(res.coords.latitude, res.coords.longitude)
        }
        if ($restaurants.length === 1) {
          map = new google.maps.Map(document.getElementById('map'), {
            disableDefaultUI: true,
            center: $restaurants[0].latlng,
            gestureHandling: 'greedy',
            zoom: 15,
          })
        } else {
          const bounds = new google.maps.LatLngBounds()
          for (const res of $restaurants) {
            bounds.extend(res.latlng)
          }
          map = new google.maps.Map(document.getElementById('map'), {
            disableDefaultUI: true,
            gestureHandling: 'greedy',
          })
          map.fitBounds(bounds)
          map.setCenter(bounds.getCenter())
        }

        const markers = $restaurants.map(res => {
          const marker = new google.maps.Marker({
            position: res.latlng,
            map,
            title: res.name,
          })
          marker.infowindow = new google.maps.InfoWindow({
            content: `
              <div>
                <h3>${res.name}</h3>
                <div>${res.price}</div>
                <a class="block" href="https://maps.google.com/?q=${res.address.split(' ').join('+')}">${res.address}</a>
                <a class="block" href="#/trips/${get(tripId)}/restaurants/${res.id}">More Details</a>
              </div>
            `,
            maxWidth: 200,
          })
          return marker
        })
          
        markers.forEach(
          m => m.addListener('click', () => {
            markers.forEach(m => m.infowindow.close())
            m.infowindow.open(map, m)
          })
        )
        try {
          const position = await userLocationPromise
          console.log('user at', position)
          userMarker(map, position)
        } catch(err) {
          console.error('failed to get position', err)
        }
      }
    })
  })
  
</script>

<svelte:head>
  <title>{$title}</title>
</svelte:head>

<a class="back" href="#/trips">◀</a>

{#if $request.loading}
  <Spinner/>
{:else if $request.error}
  <Error error={$request.error}/>
{:else}
  <h1>{$title}</h1>
  {#if $restaurants.length}
    <div id="map"></div>
    {#each $restaurants as restaurant}
      <RestaurantListItem {...restaurant} tripId={$tripId} />
    {/each}
    <a href="#/trips/{$tripId}/modify" class="modify">Modify Restaurants</a>
  {:else}
    You haven't added any restaurants yet<br/>
    <a href="#/trips/{$tripId}/modify">Click here</a> to add some!
  {/if}
{/if}

<style>
  #map {
    width: 100%;
    max-width: 768px;
    height: 300px;
    margin: 0 auto 15px;
  }

  .modify {
    font-size: 24px;
    font-weight: bold;
  }
</style>