<script>
  export let id
  export let name
  export let restaurants = []

  $: restaurantLength = (restaurants || []).length
  $: restaurantCountString = `${restaurantLength} restaurant${restaurantLength === 1 ? '' : 's'}`
</script>

<a href="#/trips/{id}" class="container">
  <h3>{name} <span class="count">({restaurantCountString})</span></h3>
</a>

<style>
  a {
    text-decoration: none;
    color: inherit;
  }

  .container {
    display: block;
    width: 100%;
    border-radius: 0.25rem;
    text-align: center;
    cursor: pointer;
    margin: 7px 0 7px 0;
  }
  
  .container:hover {
    background-color: #DDD;
  }

  h3 {
    line-height: 50px;
  }

  .count {
    font-size: 12px;
    font-weight: normal;
  }
</style>